import { ReactNode } from 'react';
import { DateTime } from 'luxon';
import { formatDateRange } from 'lib/utils/dateUtils';
import { MainContentHeader } from 'domains/Shell/MainContent/MainContentHeader/MainContentHeader';
import { MainContentHeaderTitle } from 'domains/Shell/MainContent/MainContentHeaderTitle/MainContentHeaderTitle';
import * as S from './WizardHeader.styles';

export type WizardHeaderProps = {
  endDate?: DateTime;
  location?: string;
  name: string;
  startDate: DateTime;
  title?: ReactNode;
  composedHeader?: ReactNode;
  alignTitleLeft?: boolean;
};

/* WizardHeader */
export function WizardHeader({
  endDate,
  location,
  name,
  startDate,
  title,
  composedHeader,
  alignTitleLeft = false,
}: WizardHeaderProps) {
  let formattedDate = formatDateRange(startDate, endDate);

  return (
    <>
      <MainContentHeader $alignLeft={alignTitleLeft}>
        <MainContentHeaderTitle>
          {title ?? 'Registration'}
        </MainContentHeaderTitle>
      </MainContentHeader>
      {composedHeader ? (
        composedHeader
      ) : (
        <S.Info>
          {name}: {formattedDate} {location ? `at ${location}` : null}
        </S.Info>
      )}
    </>
  );
}
