import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CaretLeftIcon } from '@la/ds-ui-components';
import * as S from './StepperDrawer.styles';
export const StepperDrawer = ({ currentNextAction, stepNumber, numberOfTotalSteps, onBackClick, handleNextClick, onCancelButtonClick, type, form, isMC, showBackButton = false, addToCart = false, }) => {
    const getActionMessage = () => {
        if (currentNextAction) {
            return currentNextAction;
        }
        if (stepNumber !== numberOfTotalSteps) {
            return 'Save and Continue';
        }
        if (isMC) {
            return 'Register and create invoices';
        }
        if (addToCart) {
            return 'Checkout and Pay';
        }
        return 'Register now';
    };
    return (_jsx(S.BottomDrawer, { children: _jsxs(S.DrawerContent, { children: [onCancelButtonClick ? (_jsx(S.CancelButtonContainer, { children: _jsx(Button, Object.assign({ variant: "text", size: "xl", onClick: onCancelButtonClick }, { children: "Cancel" })) })) : null, _jsxs(S.DrawerInnerContainer, { children: [stepNumber !== 1 || showBackButton ? (_jsx(S.BackButtonContainer, { children: _jsx(Button, Object.assign({ variant: "outline", size: "xl", leftIcon: _jsx(CaretLeftIcon, {}), onClick: onBackClick }, { children: "Back" })) })) : null, _jsx(S.ForwardButtonContainer, { children: _jsx(Button, Object.assign({ size: "xl", disabled: false, onClick: handleNextClick, type: type }, form, { children: getActionMessage() })) })] })] }) }));
};
